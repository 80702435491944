<template>
  <v-card-text>
    <v-row>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.code"
            label="Code"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.name"
            sm="12"
            cols="6"
            label="Name"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.user"
            sm="12"
            cols="6"
            label="User"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.password"
            sm="12"
            cols="6"
            label="Password"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.secret_code"
            sm="12"
            cols="6"
            label="Secret Code"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.url.url"
            sm="12"
            cols="6"
            label="Base Url"
            :rules="RequiredRules"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.url.method"
            sm="12"
            cols="6"
            label="Method"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.url.url_inq"
            sm="12"
            cols="6"
            label="Url Inq"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.url.url_pay"
            sm="12"
            cols="6"
            label="Url Pay"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.from_date"
            sm="12"
            cols="6"
            label="From Date"
        ></v-text-field>
      </v-col>
      <v-col
          sm="6"
          cols="12"
      >
        <v-text-field
            dense
            v-model="bank_model.to_date"
            sm="12"
            cols="6"
            label="To Date"
        ></v-text-field>
      </v-col>
    </v-row>
  </v-card-text>
</template>

<script>
export default {
  name: "BankFormComponent",
  data: () => ({
    RequiredRules: [
      v => !!v || 'Field cannot empty',
    ],
  }),
  props: {
    bank_model: Object,
  }
}
</script>

<style scoped>

</style>
