export function BankData() {
  return {
    code: '',
    name: '',
    user: '',
    password: '',
    secret_code: '',
    from_date: '',
    to_date: '',
    url: new UrlBank(),
  }
}

export function UrlBank() {
  return {
    url: '',
    method: '',
    params: '',
    url_inq: '',
    url_pay: '',
    url_rev: '',
    url_adv: '',
  }
}

// export function UpdateMerchantData() {
//   return {
//     code: '',
//     name: '',
//     user: '',
//     password: '',
//     secret_code: '',
//     type_payment: '',
//     callback_url: '',
//     from_date: '',
//     to_date: '',
//     current_balance: '',
//     bank: new MerchantBank(),
//   }
// }

// export function MerchantBank() {
//   return {
//     bank_account: '',
//     bank_name: '',
//     bank_branch: '',
//   }
// }
//
// export function TypeOfPayment() {
//   return [
//     {
//       value: '01',
//       text: 'deposit',
//     },
//     {
//       value: '02',
//       text: 'settlement',
//     },
//   ]
// }
